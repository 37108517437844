import { useState } from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { FaBars, FaKeyboard, FaMicrophone } from 'react-icons/fa'; // Importing icons
import "./styles.css";

const MainLayout = ({ children, setTypeChat }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to control sidebar visibility

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className='main-layout'>
      {/* Hamburger Menu */}
      <div className='hamburger' onClick={toggleSidebar}>
        <FaBars className="hamburger-icon" size={24} />
      </div>

      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <Sidebar 
          rootStyles={{
            height: "100vh",
            width: "250px", // Default width
            maxWidth: "80%", // Ensure it doesn't exceed screen width on small screens
            transition: "transform 0.3s ease", // Smooth transition
            transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)', // Slide effect
          }}
        >
          <Menu className='mt-12'>
            <MenuItem
              className="menu-item"
              icon={<FaKeyboard />}
              onClick={() => {
                setTypeChat("text");
                toggleSidebar();
              }}
            >
              Prompt écrit
            </MenuItem>
            <MenuItem
              className="menu-item"
              icon={<FaMicrophone />}
              onClick={() => {
                setTypeChat("voice");
                toggleSidebar();
              }}
            >
              Prompt oral
            </MenuItem>
          </Menu>
        </Sidebar>
      </div>

      {/* Main Content */}
      <div className='content'>
        {children}
      </div>
    </div>
  );
}

export default MainLayout;
